import { Hint } from "./Hint"
import cn from 'classnames'

interface Props {
    address: string
    first?: number
    last?: number
    showHint?: boolean
    className?: string
    isResponsive?: boolean
    showExplorerLink?: boolean
}

export default function Address({
    address,
    first = 2,
    last = 4,
    className,
    isResponsive,
    showHint,
    showExplorerLink
}: Props) {
    const content = (
        <>
            <span className='opacity-50'>{address.slice(0, 2)}</span>
            <span className={cn({
                'hidden sm:inline': isResponsive
            })}>{address.slice(2, 2 + first)}</span>
            {last && (
                <>
                    <span className='opacity-50'>...</span>
                    {address.slice(address.length - last)}
                </>
            )}
        </>
    )

    if (!showExplorerLink) {
        return (
            <Hint isHidden={!showHint} interactive content={address}>
                <div
                    className={cn("inline-block whitespace-nowrap", className)}
                    style={{ letterSpacing: '0.5px' }}
                >
                    {content}
                </div>
            </Hint >
        )
    }

    return (
        <Hint isHidden={!showHint} interactive content={address}>
            <a
                className={cn("inline-block whitespace-nowrap transition-colors duration-200 hover:text-rose-400 dark:hover:text-rose-200", className)}
                target="_blank"
                rel='noopener noreferrer'
                href={(process.env.NEXT_PUBLIC_ADDRESS_EXPLORER_LINK! as string).replace('{address}', address)}
                style={{ letterSpacing: '0.5px' }}
            >
                {content}
            </a>
        </Hint >
    )
}