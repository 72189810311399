import cn from 'classnames'
import Link from 'next/link'
import { HTMLProps, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { Hint } from 'ui/Hint'
import UIFooter from '../ui/Footer'
import Logo from '../ui/logo-icon.svg'
import Reddit from './reddit.svg'
import Twitter from './twitter.svg'
import Medium from './medium.svg'

interface Props {
}

export default function Footer({

}: Props) {
    return (
        <UIFooter>
            <div className="flex flex-col md:flex-row items-center justify-between py-4 w-full pt-4 md:pt-8 sm:snap-end gap-2">
                <div className='flex flex-row items-center justify-start order-3 md:order-1 sm:basis-1/2'>
                    {/* <FooterLink href="https://www.reddit.com/r/repayaio/">
                        <Reddit className="w-6 fill-neutral-500 hover:fill-neutral-300 transition-colors duration-200 hover:animate-pulse" />
                    </FooterLink> */}
                    <Hint
                        placement='top'
                        content={<FormattedMessage defaultMessage="Coming soon!" id="c17bcg" />}
                    >
                        <div className='mr-6 last:mr-0'>
                            <Reddit className="w-6 fill-neutral-800 hover:fill-neutral-600 transition-colors duration-200" />
                        </div>
                    </Hint>
                    <Hint
                        placement='top'
                        content={<FormattedMessage defaultMessage="Coming soon!" id="c17bcg" />}
                    >
                        <div className='mr-6 last:mr-0'>
                            <Twitter className="w-6 fill-neutral-800 hover:fill-neutral-600 transition-colors duration-200" />
                        </div>
                    </Hint>
                    <Hint
                        placement='top'
                        content={<FormattedMessage defaultMessage="Coming soon!" id="c17bcg" />}
                    >
                        <div className='mr-6 last:mr-0'>
                            <Medium className="w-6 fill-neutral-800 fill-opacity-70 hover:fill-neutral-600 transition-colors duration-200" />
                        </div>
                    </Hint>
                    <FooterLink href="https://docs.repaya.io/legal/privacy-policy">
                        <FormattedMessage defaultMessage="Privacy Policy" id="vx0nkZ" />
                    </FooterLink>
                    <FooterLink href="https://docs.repaya.io/legal/terms-of-use">
                        <FormattedMessage defaultMessage="Terms of Use" id="UhkSyx" />
                    </FooterLink>
                </div>
                <Link href="/" className='group px-0 md:px-16 inline-block relative order-1 md:order-2' style={{ top: 2 }}>
                    <Logo className='h-6 transition-opacity duration-200 opacity-20 group-hover:opacity-100' />
                </Link>
                <div className='sm:basis-1/2 sm:text-right opacity-20 text-neutral-900 dark:text-neutral-50 mt-2 md:mt-0 text-sm xs:text-base order-2 md:order-3'>
                    <FormattedMessage
                        defaultMessage="repaya.io | Web3 Payment Gateway | {year}"
                        id="bC7w+I"
                        values={{
                            year: `${new Date().getFullYear()}`
                        }}
                    />
                </div>
            </div>
        </UIFooter>
    )
}

function FooterLink({ children, ...rest }: { children: ReactNode } & HTMLProps<HTMLAnchorElement>) {
    return (
        <a rel="noopener noreferrer" target="_blank" className='mr-6 last:mr-0 text-base xs:text-md text-neutral-500 hover:text-neutral-300 transition-colors duration-200' {...rest}>
            {children}
        </a>
    )
}
