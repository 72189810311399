import Tippy, { TippyProps } from "@tippyjs/react"
import cn from 'classnames'

export type Props = {
    isHidden?: boolean
    size?: "small" | "medium" | "no-padding"
    type?: "default" | "transparent" | "danger"
} & TippyProps

export function Tooltip({
    isHidden,
    size = 'medium',
    type = "default",
    children,
    ...rest
}: Props) {
    if (isHidden) {
        return children || null
    }

    return (
        <Tippy
            animation="shift-away"
            className={cn(
                {
                    'p-2 px-4': size === 'small',
                    'p-4': size === 'medium',
                    'p-0': size === 'no-padding',
                    'bg-white dark:bg-black': type === "default",
                    'bg-red-200 dark:bg-red-900 text-red-600 dark:text-red-200 border-red-300 dark:border-red-600': type === "danger",
                    'rounded-2xl': type !== "transparent"
                }
            )}
            {...rest}
        >
            {children}
        </Tippy>
    )
}