import cn from "classnames"
import { useRouter } from "next/router"
import { ForwardedRef, forwardRef, HTMLProps, memo, ReactNode, useCallback, MouseEvent as ReactMouseEvent, MouseEventHandler } from "react"
import { Hint } from "./Hint"
import Spinner from './spinner-small.svg'

export interface Props {
    type?: 'button' | 'link' | 'submit' | 'reset'
    size?: 'default' | 'small' | 'large' | 'extra-large' | 'large-padding'
    color?: 'default' | 'primary' | 'transparent' | 'bg' | 'select' | 'air' | 'air-primary'
    href?: string
    navigate?: string
    title?: string
    isFullWidth?: boolean
    icon?: React.ComponentType<React.ComponentProps<'svg'>>
    isExternal?: boolean
    isDisabled?: boolean
    isOpenInNewTab?: boolean
    isNoMargin?: boolean
    isActive?: boolean
    isLoading?: boolean
    className?: string
    onClick?: MouseEventHandler<HTMLButtonElement> | Function
    style?: HTMLProps<HTMLButtonElement | HTMLAnchorElement>['style']
    children?: ReactNode
}

type ElementProps = {
    type: Props['type']
    isExternal: boolean
    children?: ReactNode
    isOpenInNewTab: boolean
} & HTMLProps<any>

const Element = forwardRef(function Element({ type, isOpenInNewTab, isExternal, children, ...rest }: ElementProps, ref: ForwardedRef<unknown>) {
    if (type !== 'link') {
        return <button {...rest} type={type} ref={ref as ForwardedRef<HTMLButtonElement>}>{children}</button>
    } else if (type === 'link') {
        return <a
            {...rest}
            {...(isExternal ? { rel: 'noreferrer noopener', target: '_blank' } : {})}
            {...(isOpenInNewTab ? { target: '_blank' } : {})}
            type={type}
            ref={ref as ForwardedRef<HTMLAnchorElement>}
        >
            {children}
        </a>
    }


    return null
})

const BaseButton = forwardRef(function Button(props: Props, ref) {
    const color = props.color ?? 'default'
    const size = props.size ?? 'default'
    const router = useRouter()

    const handleClick = useCallback((e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (props.onClick) {
            props.onClick(e)
        }

        if (props.navigate) {
            router.push(props.navigate)
        }

    }, [props.onClick])

    const content = (
        <Element
            type={props.type ?? 'button'}
            isExternal={props.isExternal ?? false}
            isOpenInNewTab={props.isOpenInNewTab ?? false}
            href={props.href}
            ref={ref as ForwardedRef<Element>}
            disabled={props.isDisabled ?? false}
            onClick={handleClick}
            style={props.style}
            className={cn(
                "align-baseline lh-0 no-underline whitespace-nowrap  duration-100 group select-none inline-flex flex-row items-center justify-center",
                {
                    'mr-4 last:mr-0': !props.isNoMargin,
                    'w-full': props.isFullWidth,
                    'px-3 py-1 rounded-xl': size == 'small',
                    'px-4 py-2 rounded-xl': size == 'default',
                    'px-6 py-4 text-lg rounded-xl': size == 'large' || size === 'large-padding',
                    "bg-rose-150 text-black dark:bg-rose-400 hover:bg-rose-200 dark:hover:bg-rose-100 hover:bg-opacity-90 dark:hover:bg-opacity-100 active:bg-rose-400 active:bg-opacity-70 dark:active:bg-opacity-90": color === 'primary',
                    "dark:text-neutral-400 dark:hover:text-white bg-neutral-100 hover:bg-neutral-200 active:bg-neutral-300 dark:bg-neutral-700 dark:bg-opacity-30 hover:dark:bg-opacity-30 active:dark:bg-opacity-30 hover:dark:bg-neutral-600 active:dark:bg-neutral-500": color === 'default',
                    "dark:text-neutral-300 dark:hover:text-white bg-neutral-200 bg-opacity-50 hover:bg-neutral-200 active:bg-neutral-300 dark:bg-neutral-800 dark:bg-opacity-100 hover:dark:bg-opacity-30 active:dark:bg-opacity-30 hover:dark:bg-neutral-600 active:dark:bg-neutral-500": color === 'select',
                    "bg-white hover:bg-opacity-80 active:bg-neutral-100 dark:bg-black hover:dark:bg-black hover:dark:bg-opacity-30 text-neutral-900 dark:text-neutral-50": color === 'bg',
                    "hover:bg-neutral-50 hover:dark:bg-neutral-900 active:bg-neutral-100": color === 'transparent',
                    " dark:hover:text-white bg-white bg-opacity-0 hover:bg-opacity-5  active:bg-neutral-600 active:bg-opacity-30": color === 'air',
                    " dark:hover:text-white bg-white bg-opacity-10 hover:bg-opacity-20  active:bg-neutral-600 active:bg-opacity-30": color === 'air-primary',
                    'text-neutral-900': color !== 'transparent' && color !== 'bg',
                    'opacity-60 dark:opacity-40 cursor-default pointer-events-none': props.isDisabled || props.isLoading,
                    'relative': !props.className?.includes('absolute')
                },
                props.className
            )}
        >
            <div className="w-full h-full inline-flex flex-row items-center justify-center">
                {!props.isLoading && props.icon ? <props.icon className={cn("inline-block opacity-80 group-hover:opacity-100 fill-neutral-900", {
                    'mr-2': !!props.children,
                    'w-5': size !== 'large',
                    'w-8': size == 'large',
                    ' dark:fill-neutral-50': color !== 'primary'
                })} /> : null}
                {props.isLoading ? <Spinner className={cn("w-4 mr-3 inline-block animate-spin fill-neutral-900", {
                    ' dark:fill-neutral-50': color !== 'primary'
                })} /> : null}
                {props.children && (props.children)}
            </div>
        </Element>
    )

    if (!props.title) {
        return content
    }

    return (
        <Hint
            delay={[500, null]}
            content={props.title}
        >
            {props.isDisabled
                ? (
                    <div className="inline-block">
                        {content}
                    </div>
                )
                : content
            }
        </Hint>
    )
})

export const Button = memo(BaseButton)