import cn from 'classnames'
import { ReactNode } from 'react'

interface Props {
    children?: ReactNode
}

export default function Header({
    children
}: Props) {
    return (
        <div className='relative w-full bg-neutral-50 dark:bg-neutral-900 border border-x-0 border-b-0 border-neutral-100 dark:border-neutral-800 dark:border-opacity-50  ' style={{ height: '128px' }}>
            {/* <div className="absolute z-1000 inset-0 bg-neutral-50 dark:bg-red-900" style={{ top: 'calc(100vh - 1px)', bottom: -300 }}></div> */}
            <div className="relative z-20 p-4 pt-5 px-4 xl:px-12">
                {children}
            </div>
        </div>
        
    )
}