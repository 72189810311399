import { memo, ReactNode, useCallback, useMemo, MouseEvent as ReactMouseEvent, HTMLProps } from "react"
import cn from 'classnames'
import { Button, Props as ButtonProps } from "./Button"
import { Tooltip, Props as TooltipProps } from "./Tooltip"
import Arrow from "./arrow.svg"
import { useCallbackStopPropagation, useKeyMemo, useToggleState } from "../common/hooks"

export interface Option<T, R> {
    label: R
    value: T
}

export interface OptionProps<T, R> {
    option: Option<T, R>
    isSelected: boolean
    onClick: (e: ReactMouseEvent<Element, MouseEvent>) => unknown
    renderOption?: null | ((option: Option<T, R>, isSelected: boolean) => ReactNode)
}

function OptionComponent<T, R>({
    option,
    isSelected,
    onClick,
    renderOption
}: OptionProps<T, R>) {
    let label = option.label

    return (
        renderOption ? (
            <div onClick={onClick}>
                {renderOption(option, isSelected)}
            </div>
        ) : (
            <div className="mb-2 last:mb-0">
                <Button
                    isFullWidth
                    color='transparent'
                    onClick={onClick}
                >
                    {label as ReactNode}
                </Button>
            </div>
        )
    )
}

export const Option = memo(OptionComponent) as typeof OptionComponent


interface Props<T extends string | number | null, R> {
    options: Option<T, R>[] | null
    value?: T | null
    onChange?: (value: T) => unknown
    error?: { current: ReactNode } | ReactNode | null
    isDisabled?: boolean
    placeholder?: ReactNode
    placement?: TooltipProps['placement']
    size?: ButtonProps['size']
    icon?: ButtonProps['icon'],
    style?: HTMLProps<any>['style']
    color?: ButtonProps['color']
    className?: string
    renderOption?: null | ((option: Option<T, R>, isSelected: boolean) => ReactNode)
    renderValue?: null | ((option: Option<T, R>, isOpen: boolean) => ReactNode)
}

let tooltipKeyId = 0

function SelectComponent<T extends string | number | null, R>({
    className,
    options,
    color,
    value,
    size,
    icon,
    error,
    style,
    placement = 'right',
    placeholder,
    isDisabled,
    onChange,
    renderOption,
    renderValue
}: Props<T, R>) {
    const selectedOption = useMemo(() => options?.find(o => o.value === value), [options, value])
    renderValue = renderValue ?? (option => option.label as ReactNode)
    const [isOpenOptions, setToggleOptions] = useToggleState(false)
    const handleOptionsClick = useCallbackStopPropagation(() => setToggleOptions(), [setToggleOptions])
    const handleOptionsHide = useCallback(() => setToggleOptions(false), [setToggleOptions])
    const handleOutsideClick = useCallback((_: any, e: MouseEvent) => {
        e.stopPropagation()
        setToggleOptions(false)
    }, [setToggleOptions])

    const handleOptionClick = useKeyMemo(index => (e: ReactMouseEvent<Element, MouseEvent>) => {
        e.stopPropagation()
        setToggleOptions(false)

        const value = options![+index].value
        if (onChange) {
            onChange(value)
        }
    }, [options, setToggleOptions, onChange])

    const tooltipKey = useMemo(() => ++tooltipKeyId, [error])

    return (
        <div style={style} className={cn("relative", {
            'inline-block': className?.includes('inline-block'),
            'inline': className?.includes('inline') && !className?.includes('inline-block'),
            'h-full': className?.includes('h-full'),
        })}>
            <Tooltip key={tooltipKey} showOnCreate isHidden={!error} placement="bottom-start" size="small" trigger="manual"
                type="danger" content={(error && typeof error === "object" && "current" in error) ? error?.current : error}
            >
                <div className="absolute z-0 inset-0"></div>
            </Tooltip>
            <Tooltip isHidden={!isOpenOptions || (!options || options.length < 2)} showOnCreate hideOnClick={false} placement={placement}
                interactive trigger='click'
                size="no-padding"
                popperOptions={{ strategy: 'fixed' }}
                onUntrigger={handleOptionsHide}
                onClickOutside={isOpenOptions ? handleOutsideClick : undefined}
                content={(
                    <div>
                        {(options ?? []).map((option, i) => (
                            <Option
                                renderOption={renderOption}
                                key={`${i}`}
                                option={option}
                                isSelected={option.value === value}
                                onClick={handleOptionClick(i)}
                            />
                        ))}
                    </div>
                )}
                zIndex={100}
            >
                <Button size={size} color={color || 'select'} isDisabled={isDisabled} isActive={isOpenOptions} onClick={handleOptionsClick}
                    className={cn("w-full", className, { 'opacity-100': isOpenOptions, 'border border-red-500 bg-red-100': error })} icon={icon}
                >
                    {((selectedOption != null || placeholder) || (selectedOption == null && !icon)) && (
                        <div className="flex flex-row justify-between items-center w-full relative pr-8">
                            <div className={cn("whitespace-nowrap", {
                                'opacity-50': selectedOption == null
                            })}>
                                {selectedOption != null ? renderValue(selectedOption, isOpenOptions) : placeholder}
                            </div>
                            {(options && options.length > 1) && (
                                <Arrow
                                    style={isOpenOptions ? { top: 'calc(50% - 1px)', transform: 'translateY(-50%) rotate(180deg)' } : { top: 'calc(50% - 3px)', transform: 'translateY(-50%)' }}
                                    className={cn("w-4 h-4 inline align-middle absolute right-0 fill-black dark:fill-white", {
                                        'opcity-100': isOpenOptions,
                                        'opacity-50 group-hover:opacity-100': !isOpenOptions,
                                    })} />
                            )}
                        </div>
                    )}
                </Button>
            </Tooltip>
        </div>
    )
}

export const Select = memo(SelectComponent) as typeof SelectComponent