import Tippy, { TippyProps } from "@tippyjs/react"

type Props  = TippyProps & {
    isHidden?: boolean
}

export function Hint({
    children, isHidden, ...rest
}: Props) {
    if (isHidden) {
        return <>{children}</>
    }

    return (
        <Tippy
            animation="shift-away"
            arrow={false}
            className="bg-neutral-700 text-white p-2 px-4 opacity-95 text-sm rounded-2xl light-button"
            {...rest}
        >{children}</Tippy>
    )
}