import cn from 'classnames'
import { ReactNode, useState, useEffect } from 'react'

interface Props {
    children?: ReactNode
}

export default function ClientSide({
    children
}: Props) {
    const [show, setShow] = useState(false)

    useEffect(() => {
        setShow(true)
    }, [])

    if (!show) return null
    
    return <>{children}</>
}